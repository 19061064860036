import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const verifyPhone = async (
  apiKey,
  localState,
  setPhoneError,
  setIsPhoneValidated
) => {
  try {
    const phoneResponse = await API.post('brains', '/external/phoneLookup', {
      credentials: 'include',
      body: {
        phone: localState.phone,
        apiKey,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (phoneResponse.status !== 200) {
      setPhoneError('Please enter your cell phone number');
      setIsPhoneValidated(false);
    } else {
      setPhoneError('');
      setIsPhoneValidated(true);
    }
  } catch (err) {
    console.log('err', err);
    setPhoneError('Please enter your cell phone number');
    setIsPhoneValidated(false);
  }
};

const getOrganizations = async (
  apiKey,
  setOrganizations
) => {
  try{
    const body = {
      apiKey
    }
    const response = await API.post('brains', '/external/organizations', {
      credentials: 'include',
      body,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    setOrganizations(response.data);
  } catch (err) {
    console.log(err);
    return;
  }
}

const createVisit = async (
  apiKey,
  formData,
  setOnIntro,
  setError,
  navigate,
  turnstileToken
) => {
  const masterId = uuidv4();
  const body = {
    formObj: formData,
    company: 'goodyHealth',
    visitType: 'videoCovid',
    apiKey,
    masterId
  };

  try {
    const visitCreationResponse = await API.post(
      'brains',
      '/visit/goodyIntake',
      {
        credentials: 'include',
        body,
        headers: {
          'Content-Type': 'application/json',
          'X-Turnstile-Token': turnstileToken,
        },
      }
    );
    if (visitCreationResponse.status !== 200) {
      setError(
        'Something went wrong, please check all your info and try again.'
      );
      return;
    }
    navigate('/success');
    setOnIntro(false);
  } catch (err) {
    setError('Something went wrong, please check all your info and try again.');
    return;
  }
};

export const routerMap = {
  verifyPhone,
  createVisit,
  getOrganizations
};
