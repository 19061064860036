import React, {  useState } from "react";

import DemographicForm from "../SimpleSubmit/DemographicForm";

import { introPage } from "../DataFiles/standardText";
import { routerMap } from "../utils/router";

import "../MainForm/MainForm.css";
import TurnstileWidget from "../Turnstile/TurnstileWidget";


const IntroPage = (props) => {
  const { setOnIntro, navigate } = props;

  const { createVisit } = routerMap;

  const apiKey =
    process.env.REACT_APP_STAGE === "prod"
      ? "6Bn7YXNoeNhW42B3iZyi"
      : "vCwD6xhEGPEAoaGcVp1D";

  const [localState, setLocalState] = useState({
    firstName: "",
    lastName: "",
    dob: "",
    email: "",
    phone: "",
    organizationName: "",
    organizationEmail: "",
    consentsSigned: false
  });
  const [isPendingData, setIsPendingData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [turnstileToken, setTurnstileToken] = useState("");
  const handleTurnstileToken = (token) => {
    setTurnstileToken(token);

  }
  const setChecked = () => {
    setLocalState({
      ...localState,
      consentsSigned: !localState.consentsSigned
    });
  };

  const handleCreateVisit = async () => {
    try {
      setIsPendingData(true);
      await createVisit(apiKey, localState, setOnIntro, setError, navigate, turnstileToken);
      setIsPendingData(false);
    } catch (error) {
      console.error("handleCreateVisit error", error);
    }
  };

  return (
      <div className="pageContainer">
      <TurnstileWidget onTokenChange={handleTurnstileToken} />
        <div className="App-bg">
        <div className="longShape"></div>
        <div className="roundShape bg-ele-one"></div>
        <div className="roundShape bg-ele-two"></div>
        <div className="roundShape bg-ele-three"></div>
        <div className="roundShape bg-ele-four"></div>
        <div className="roundShape bg-ele-five"></div>
      </div>
      <div className="introContainer">
        <div className="introHeader">
          <div className="messageHeader">Good-To-Go With Goody</div>
          <div className="introSubheader">
            <div>{introPage.header}</div>
          </div>
          <DemographicForm
            apiKey={apiKey}
            setValidated={setValidated}
            validated={validated}
            localState={localState}
            setLocalState={setLocalState}
          />

          {error && <div className="checkoutError">{error}</div>}
          <div className="introConsents">
            <input
              type="checkbox"
              name="consents"
              onClick={setChecked}
              className="checkBox"
            />
            <span>
              I agree to the{" "}
              <a
                href="https://customerconsents.s3.amazonaws.com/Beluga_Health_Telemedicine_Informed_Consent.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Terms and Conditions
              </a>
              {` & `}
              <a
                href="https://customerconsents.s3.amazonaws.com/Beluga_Health_Telemedicine_Informed_Consent.pdf"
                target="_blank"
                rel="noreferrer"
                className="link"
              >
                Privacy Policy
              </a>
            </span>
          </div>
          <button
            className="orderButton"
            onClick={handleCreateVisit}
            disabled={!validated || isPendingData}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default IntroPage;
