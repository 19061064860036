export const introPage = {
  header: `Welcome!`,
};

export const paymentSuccess = {
  header: `Thank you! Your information was submitted successfully.`,
  body: `We will now review your test recording.`,
  footer: `Beluga Health is a US licensed telemedicine provider.`,
};

export const dataSecure = {
  header:
    'Ready to test? Fill out the form to get started.',
};

export const checkoutText = {
  header: `It looks like <span class="nowrap">Relivion® MG</span> may be right for you!`,
  body: `Your doctor consult will be provided by Beluga Health, a telemedicine provider that serves patients across the country. Your $30 payment goes directly to Beluga Health and covers the cost of your doctor consult. If medically appropriate, treatment may be prescribed to you. The doctor will contact you within the next 24 hours (often times much sooner) via email and text message to complete your consultation.`,
};

