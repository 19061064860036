import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import InputField from "./InputField";

import { dataSecure } from "../DataFiles/standardText";
import { routerMap } from "../utils/router";

import "../MainForm/MainForm.css";

const getMaxDob = () => {
  const today = new Date();
  let month = (today.getMonth() + 1).toString();
  let date = today.getDate().toString();
  if (month.length === 1) {
    month = "0" + month;
  }
  if (date.length === 1) {
    date = "0" + date;
  }
  return `${today.getFullYear()}-${month}-${date}`;
};

const DemographicForm = (props) => {
  const {
    apiKey,
    setValidated,
    setLocalState,
    localState
  } = props;

  const [phoneError, setPhoneError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);
  const [focusingField, setFocusingField] = useState("");
  const [organizations, setOrganizations] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { verifyPhone, getOrganizations } = routerMap;

  const handleChange = (e) => {
    switch (e.target.name) {
      case "dob":
        // note: e.target.value will yield a string with format YYYY-MM-DD
        const timestamp = e.target.valueAsNumber;
        const dateObj = new Date(timestamp);
        // since the hours part is in UTC, just use UTC so it doesn't mess up
        // the date based on timezone
        const month = dateObj.getUTCMonth() + 1;
        const date = dateObj.getUTCDate();
        setLocalState({
          ...localState,
          dob: `${month.toString().padStart(2, '0')}/${date.toString().padStart(2, '0')}/${dateObj.getUTCFullYear()}`,
        });
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleInputChange = (input) => {
    if (input) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  };

  const handlePhoneChange = (e) => {
    const justDigits = /[^0-9]/g;
    const cleanPhone = e.target.value.replace(justDigits, "");
    setLocalState({
      ...localState,
      phone: cleanPhone,
    });
  };

  const validatePhone = useCallback(async () => {
    setIsValidatingPhone(true);
    if (localState.phone.length >= 10) {
      await verifyPhone(apiKey, localState, setPhoneError, setIsPhoneValidated);
      setIsValidatingPhone(false);
    } else {
      setPhoneError("Please enter your cell phone number");
      setIsPhoneValidated(false);
      setIsValidatingPhone(false);
      return;
    }
  }, [localState.phone.length, verifyPhone]);

  const handlePhoneBlur = async () => {
    validatePhone();
    setFocusingField("");
  };

  const handleFocus = (n) => {
    setFocusingField(n);
  };

  const handleOrgFocus = () => {
    setFocusingField("org");
  };

  const handleBlur = () => {
    setFocusingField("");
  };

  const handleAgeBlur = () => {
    ageCheck();
  };

  const ageCheck = () => {
    const now = new Date();
    const eighteenYearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const dobArray = localState.dob ? localState.dob.split("/") : [0, 0, 0];
    const dobMonth = dobArray[0];
    const dobDate = dobArray[1];
    const dobYear = dobArray[2];
    const dob = new Date(dobYear, dobMonth - 1, dobDate);
    if (dob > eighteenYearsAgo) {
      // TODO: do we still want to do this check?
      setAgeError("Testing not available for patients under the age of 18.");
      setValidated(false);
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  const handleEmailBlur = () => {
    const regex = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/;
    const regMatch = regex.test(localState.email);
    if (!regMatch) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
    setFocusingField("");
  };

  const options = organizations.map((org) => {
    return {
      value: org.name,
      label: org.name,
    };
  });

  const handleOrgSelect = (option) => {
    const foundOrg = organizations.find((org) => org.name === option.value);
    setLocalState({
      ...localState,
      organizationName: foundOrg.name,
      organizationEmail: foundOrg.email,
    });
  };

  useEffect(() => {
    if (
      localState.phone.length &&
      !isPhoneValidated &&
      !isValidatingPhone &&
      !phoneError
    ) {
      validatePhone();
    }
  }, [
    localState.phone,
    isPhoneValidated,
    validatePhone,
    isValidatingPhone,
    phoneError,
  ]);

  useEffect(() => {

    const dobMatch = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(localState.dob);
    if (
      localState.firstName.length &&
      localState.lastName.length &&
      localState.email.length &&
      localState.phone.length &&
      localState.organizationName.length &&
      localState.consentsSigned &&
      dobMatch &&
      !ageError &&
      !phoneError &&
      !emailError &&
      isPhoneValidated
    ) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [
    localState,
    ageError,
    phoneError,
    emailError,
    isPhoneValidated,
    setValidated,
  ]);

  useEffect(() => {
    if (!organizations.length) {
      const asyncFetch = async () => {
        await getOrganizations(apiKey, setOrganizations);
      };
      asyncFetch();
    }
  }, []);

  return (
    <div className="demographicContainer">
      <div className="checkoutForm">
        <div>
          <div className="dataSecureText">{dataSecure.header}</div>
        </div>

        <div className="demographicSubRow">
          <InputField
            focusingField={focusingField}
            handleOnChange={handleChange}
            handleFocus={handleFocus}
            handleOnBlur={handleBlur}
            inputInfo={{
              inputName: "firstName",
              placeholder: "First Name",
              inputClassName: "checkoutInput",
            }}
          />

          <InputField
            focusingField={focusingField}
            handleOnChange={handleChange}
            handleFocus={handleFocus}
            handleOnBlur={handleBlur}
            inputInfo={{
              inputName: "lastName",
              placeholder: "Last Name",
              inputClassName: "checkoutInput",
            }}
          />
        </div>

        <div className="demographicSubRow">
          <InputField
            focusingField={focusingField}
            handleOnChange={handlePhoneChange}
            handleFocus={handleFocus}
            handleOnBlur={handlePhoneBlur}
            inputInfo={{
              inputName: "phone",
              placeholder: "Phone Number",
              type: "tel",
              maxLength: "10",
              inputClassName: "checkoutInput",
              errorClassName: "checkoutInputError",
              errorMsg: phoneError,
            }}
          />
          <InputField
            focusingField={focusingField}
            handleOnChange={handleChange}
            handleFocus={handleFocus}
            handleOnBlur={handleAgeBlur}
            inputInfo={{
              type: "date",
              inputName: "dob",
              placeholder: "Date of Birth",
              inputClassName: "checkoutInput",
              errorClassName: "checkoutInputError",
              errorMsg: ageError,
              min: "1900-01-01",
              max: getMaxDob(),
            }}
          />
        </div>

        <div className="demographicSubRow singleItemRow">
          <InputField
            focusingField={focusingField}
            handleOnChange={handleChange}
            handleFocus={handleFocus}
            handleOnBlur={handleEmailBlur}
            inputInfo={{
              inputName: "email",
              placeholder: "Email",
              inputClassName: "checkoutInput",
              errorClassName: "checkoutInputError",
              errorMsg: emailError,
            }}
          />
        </div>

        <div className="demographicSubRow singleItemRow">
          <div className="innerSubRow">
            <div className="inputGroup">
              <div
                className="inputLabel"
                style={{
                  visibility:
                    focusingField === "org" || localState?.organizationName
                      ? "visible"
                      : "hidden",
                }}
              >
                Organization/Event
              </div>
              <Select
                className="checkoutInput selector"
                classNamePrefix="demoSelector"
                onChange={handleOrgSelect}
                onInputChange={handleInputChange}
                onFocus={handleOrgFocus}
                onBlur={handleBlur}
                options={options}
                placeholder={
                  focusingField === "org" ? "" : "Organization/Event"
                }
                noOptionsMessage={() => "No matches found"}
                menuIsOpen={isMenuOpen}
                unstyled
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    fontFamily: "Poppings Regular",
                    fontSize: "12px",
                    textAlign: "left",
                    marginTop: "-12px",
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicForm;
