import React from "react";

import "../MainForm/MainForm.css";

const SuccessPage = () => {
  return (
    <div className="pageContainer">
      <div className="App-bg">
        <div className="longShape"></div>
        <div className="roundShape bg-ele-one"></div>
        <div className="roundShape bg-ele-two"></div>
        <div className="roundShape bg-ele-three"></div>
        <div className="roundShape bg-ele-four"></div>
        <div className="roundShape bg-ele-five"></div>
      </div>

      <div className="prevFormCont successPage">
        <div className="successContainer">
          <div className="successContent">
            <div className="messageHeader">Good-To-Go With Goody</div>
            <div className="successHeader">Success!</div>
            <div className="successBody">
              <p>
              Thank you for submitting your information. You will now receive a text message and email link to begin your test recording.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
