import React, { useState } from "react";

import "../MainForm/MainForm.css";

const InputField = (props) => {
  const {
    inputInfo,
    focusingField,
    handleOnChange,
    handleFocus,
    handleOnBlur,
  } = props;
  const {
    inputName,
    placeholder,
    type,
    maxLength,
    inputClassName,
    errorClassName,
    errorMsg,
    min,
    max,
  } = inputInfo;
  const isFocus = inputName === focusingField;
  const [isInputTouched, setIsInputTouched] = useState(false);
  const [isInputFilled, setIsInputFilled] = useState(false);

  const onChange = (e) => {
    handleOnChange(e);
  };

  const onFocus = () => {
    setIsInputTouched(true);
    handleFocus(inputName);
  };

  const onBlur = (e) => {
    if (e.target.value) {
      setIsInputFilled(true);
    } else {
      setIsInputFilled(false);
    }
    handleOnBlur();
  };

  let inputType = type;

  // before the input is touched,
  // leave it as text so that placeholder works
  if (type === "date") {
    if (isInputTouched) {
      inputType = "date";
    } else {
      inputType = "text";
    }
  }

  return (
    <div className="innerSubRow">
      <div className="inputGroup">
        <div
          className="inputLabel"
          style={{
            visibility: isFocus || isInputFilled ? "visible" : "hidden",
          }}
        >
          {placeholder}
        </div>
        <input
          name={inputName}
          placeholder={isFocus ? "" : placeholder}
          type={inputType}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          maxLength={maxLength}
          className={
            errorMsg ? `${inputClassName} ${errorClassName}` : inputClassName
          }
          min={min}
          max={max}
        />
      </div>
      {errorMsg && <div className="checkoutError">{errorMsg}</div>}
    </div>
  );
};

export default InputField;
