/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";

import IntroPage from "./IntroPage/IntroPage";
import SuccessPage from "./SuccessPage/SuccessPage";

import './App.css';


const App = () => {
  const [onIntro, setOnIntro] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (onIntro) {
      navigate('/');
    }
  }, []);

  return (
    <div className="App">
      <Routes>
        {onIntro ?
          <Route
            exact
            path='/'
            element={
              <IntroPage
                setOnIntro={setOnIntro}
                navigate={navigate}
              />
            }
          />
        :
          <Route
            path='/success'
            element={
              <SuccessPage />
            }
          />
        }
      </Routes>
    </div>
  );
}

export default App;
